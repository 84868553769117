import React, { useEffect, useState } from 'react';

const ReferrerTracker = () => {
  const [currentLocation, setCurrentLocation] = useState('Loading...');

  useEffect(() => {
    const getCurrentLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        
        console.log('Raw API response:', data);
        
        if (data.city && data.region) {
          const locationStr = `${data.city}, ${data.region}`;
          console.log('Current location:', locationStr);
          setCurrentLocation(locationStr);
        } else {
          console.error('API returned incomplete data:', data);
          setCurrentLocation('Unknown Location');
        }
      } catch (error) {
        console.error('Error fetching location:', error);
        setCurrentLocation('Unknown Location');
      }
    };

    getCurrentLocation();
  }, []);

  return (
    <div className="flex-col text-sm font-light text-gray-500">
      <div>Made in Toronto</div>
      <div>Viewing from {currentLocation}</div>
    </div>
  );
};

export default ReferrerTracker;