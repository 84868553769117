import React from "react";

const Body = () => {
  return (
    <div className="flex-col text-base items-center justify-center font-inter font-light antialiased">
      <p className="mt-4">
        I'm a product focused operator based in Palo Alto, California.
      </p>
      <p className="mt-4">
        I love crafting beautiful and intuitive tools that optimize the useful process. 
        I spend a lot of my time learning from thoughtful people who build tasteful software 
        for the everyday user.
      </p>
      <p className="mt-4">
        I'm currently the first hire at {" "}
        <a
          href="https://mybiogenesis.com"
          style={{
            color: 'black',
            textDecoration: 'none',
            borderBottom: '1px dotted black',
            transition: 'color ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.target.style.color = 'gray';
            e.target.style.borderBottomColor = 'gray';
          }}
          onMouseLeave={(e) => {
            e.target.style.color = 'black';
            e.target.style.borderBottomColor = 'black';
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Biogenesis
        </a>
        , where I’m leading product, operations, and GTM for clinical trial software. I also advise and invest in those building the future.
      </p>
    </div>
  );
};

export default Body;
