import React from 'react';

const Blog = ({ blog }) => {
    return (
        <div className="font-inter antialiased">
            <h1 className="text-xl font-regular text-black mb-1">
                {blog.title}
            </h1>
            <div className="text-gray-500 font-light text-sm mb-6">
                {new Date(blog.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}
            </div>
            <div className="text-base font-light whitespace-pre-wrap">
                {blog.body}
            </div>
        </div>
    );
};

export default Blog;
