import React from "react";
import Title from "../components/Title";
import Body from "../components/Body";
import Essays from "../components/Essays";
import Contact from "../components/Contact";
import ReferrerTracker from "../components/ReferrerTracker";

function Main() {
  return (
    <div className="items-center justify-center h-screen space-y-6">
      <Title />
      <Body />
      {/* <Essays /> */}
      <Contact />
      <ReferrerTracker />
    </div>
  );
}

export default Main;
