import React from "react";
import { useParams, Navigate } from "react-router-dom";
import Blog from "../components/Blog";
import blogsData from '../data/blogs.json';

function BlogPage() {
  const { root } = useParams();
  const blog = blogsData.blogs.find((blog) => blog.root === root);

  if (!blog) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="items-center justify-center h-screen">
      <div className="flex items-center mb-6">
        <a
          href="/"
          className="text-black font-light text-sm"
          style={{
            color: 'text-black',
            textDecoration: 'none',
            borderBottom: '1px dotted black',
            transition: 'color ease-in-out',
          }}
          onMouseEnter={(e) => {
              e.target.style.color = 'gray';
              e.target.style.borderBottomColor = 'gray';
          }}
          onMouseLeave={(e) => {
              e.target.style.color = 'black';
              e.target.style.borderBottomColor = 'black';
          }}
        >
          ← Back
        </a>
      </div>
      <Blog blog={blog} />
    </div>
  );
}

export default BlogPage;