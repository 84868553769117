import React from "react";

const Contact = () => {
  return (
    <div>
      <div className="flex font-inter text-white antialiased">
        <h1 className="w-full text-xl font-regular text-black">
          Contact
        </h1>
        <hr />
      </div>
      <div className="flex-col text-base items-center justify-center font-inter font-light antialiased">
      <p className="mt-4">
        Feel free to {" "}
        <a
          href="mailto:brandonoliveryu@gmail.com"
          style={{
            color: 'black',
            textDecoration: 'none',
            borderBottom: '1px dotted black',
            transition: 'color ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.target.style.color = 'gray';
            e.target.style.borderBottomColor = 'gray';
          }}
          onMouseLeave={(e) => {
            e.target.style.color = 'black';
            e.target.style.borderBottomColor = 'black';
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          reach out
        </a>
        {""} via email. I also write a {" "}
        <a
          href="https://brandonyu.substack.com/"
          style={{
            color: 'black',
            textDecoration: 'none',
            borderBottom: '1px dotted black',
            transition: 'color ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.target.style.color = 'gray';
            e.target.style.borderBottomColor = 'gray';
          }}
          onMouseLeave={(e) => {
            e.target.style.color = 'black';
            e.target.style.borderBottomColor = 'black';
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
            monthly update
        </a>
        {""} and am active on {" "}
        <a
          href="https://twitter.com/brandonoliveryu"
          style={{
            color: 'black',
            textDecoration: 'none',
            borderBottom: '1px dotted black',
            transition: 'color ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.target.style.color = 'gray';
            e.target.style.borderBottomColor = 'gray';
          }}
          onMouseLeave={(e) => {
            e.target.style.color = 'black';
            e.target.style.borderBottomColor = 'black';
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
            Twitter
        </a>
        .
      </p>
    </div>
    </div>
  );
};

export default Contact;