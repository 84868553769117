import React from "react";

const Title = () => {
  return (
    <div className="flex font-inter text-white antialiased">
      <h1 className="w-full text-xl font-regular text-black">
        Hi, I'm Brandon 👋
      </h1>
    </div>
  );
};

export default Title;
