import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Main from "./pages/Main";
import BlogPage from "./pages/BlogPage";

function App() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
      <Router>
        <div className={`items-center justify-center h-screen p-20 w-full max-w-[900px] mx-auto overflow-hidden bg-white dark:bg-gray-900 transition-colorstransition-opacity duration-1000 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
          <Routes>
            <Route path="/:root" element={<BlogPage />} />
            <Route path="/" element={<Main />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;
